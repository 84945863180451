<template>
  <div class="tooltip">
    <span class="tooltip__text" :class="'tooltip__text--' + currentPosition">{{
      text
    }}</span>
    <slot></slot>
  </div>
</template>

<script>
const defaultPosition = 'bottom';

export default {
  name: 'SmTooltip',

  props: {
    text: {
      type: String,
      required: true,
    },

    /**
     * Допустимые значения перечислены в listOfPosition
     */
    position: {
      type: String,
      required: false,
      default: defaultPosition,
    },
  },

  data() {
    return {
      listOfPosition: ['top', 'right', 'bottom', 'left'],
    };
  },

  computed: {
    currentPosition() {
      if (!this.listOfPosition.includes(this.position)) {
        console.error(this.errorPositionMessage);
        return defaultPosition;
      }

      return this.position;
    },

    errorPositionMessage() {
      return `Неверно указан prop для position. Убедитесь, что Вы указали правильное значение. Разрешено использовать следующие значения: ${this.listOfPosition.join(
        ', '
      )}.`;
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip__text {
  position: absolute;
  z-index: 1;
  visibility: hidden;

  width: 120px;
  padding: 10px 0;

  color: var(--white);
  text-align: center;

  border-radius: 6px;
  background-color: var(--black);

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip__text {
  visibility: visible;

  opacity: 1;
}

/* tooltip position */
.tooltip__text--top {
  bottom: 100%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -60px; /* (width/2 = 60), для центрирования tooltip */

  &::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;

    margin-left: -5px;

    border-width: 5px;
    border-style: solid;
    border-color: var(--black) transparent transparent transparent;
  }
}

.tooltip__text--right {
  top: -5px;
  left: 105%;

  margin-left: 5px;

  &::after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 100%;

    margin-top: -5px;
    border-width: 5px;

    border-style: solid;
    border-color: transparent var(--black) transparent transparent;
  }
}

.tooltip__text--bottom {
  top: 100%;
  left: 50%;

  margin-top: 5px;
  margin-left: -60px; /* (width/2 = 60), для центрирования tooltip */

  &::after {
    content: ' ';
    position: absolute;
    bottom: 100%;
    left: 50%;

    margin-left: -5px;
    border-width: 5px;

    border-style: solid;
    border-color: transparent transparent var(--black) transparent;
  }
}

.tooltip__text--left {
  top: -5px;
  right: 105%;

  margin-right: 5px;

  &::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 100%;

    margin-top: -5px;
    border-width: 5px;

    border-style: solid;
    border-color: transparent transparent transparent var(--black);
  }
}
</style>

<template>
  <div class="permission-error-message">
    <sm-icon class="permission-error-icon" outline name="ban" /> У Вас не
    достаточно прав. За более подробной информацией обратитесь к администратору.
  </div>
</template>

<script>
import smIcon from '@/components/common/SmIcon.vue';
export default {
  name: 'smPermissionErrorMessage',

  components: {
    smIcon,
  },
};
</script>

<style lang="scss">
.permission-error-message {
  display: flex;
  align-items: center;
  padding: 20px;

  line-height: 1;
  color: var(--red);

  // border: 1px solid var(--red);
  border-radius: 10px;
  background-color: rgba(var(--rgb-red), 0.1);
}

.permission-error-icon {
  margin-right: 15px;
}
</style>
